.u-header {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.login-container {
    margin-top: 100px;
}

.login-header {
    background-color: #8900f2;
    font-size: 40px;
    text-align: center;
    color: white;
}

.login-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #8900f2;
  border-radius: 0.6em;
  color: #8900f2;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.login-btn:hover, .login-btn:focus {
  color: #fff;
  outline: 0;
}

.first-login {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-login:hover {
  box-shadow: 0 0 40px 40px #8900f2 inset;
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 5.35rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
  background-color: white;
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: white;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  border: solid 2px #8900f2;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.btn-div {
    text-align: right;
    margin-right: 50px;
}

.font {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
} 

.login-card-footer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-size: 15px;
}

.login-switcher {
  color: blue;
  cursor: pointer;
}


/* Dark Mode */

.font-dark {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
} 

.u-header-dark {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
    color: white;
}

.login-header-dark {
    background-color: #6ed3e7;
    font-size: 40px;
    text-align: center;
    color: white;
}

.login-btn-dark {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #6ed3e7;
  border-radius: 0.6em;
  color: #6ed3e7;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.login-btn-dark:hover, .login-btn-dark:focus {
  color: #fff;
  outline: 0;
}

.first-login-dark {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-login-dark:hover {
  box-shadow: 0 0 40px 40px #6ed3e7 inset;
}

.signup-card-dark {
  background-color: #46494c;
}

.form__input-dark {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1.2rem;
    margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: #46494c;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  border: solid 2px #6ed3e7;
  transition: all 0.3s;
}

.form__label-dark {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 4.9rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
  background-color: #46494c;
  color: white;
}

.form__input-dark:placeholder-shown + .form__label-dark {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.login-card-footer-dark {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-size: 15px;
  color: white;
}