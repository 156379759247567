.c-wrapper {
  background-color: #D9D9D9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 250vh;
  max-height: 100vh;
}

.c-header {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.c-load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #8900f2;
  width: 100%;
  height: 0%;
}

.c-load-text {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.contact-container {
    margin-top: 50px;
}

.submit-container {
    margin-top: 200px;
}

.contact-header {
    background-color: #8900f2;
    font-size: 40px;
    text-align: center;
    color: white;
}

.name-input {
  display: inline;
  float: inline-start;
}

.email-input {
  display: inline;
  float: inline-end;
}

.contact-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #8900f2;
  border-radius: 0.6em;
  color: #8900f2;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.contact-btn:hover, .contact-btn:focus {
  color: #fff;
  outline: 0;
}

.first-contact {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-contact:hover {
  box-shadow: 0 0 40px 40px #8900f2 inset;
}

.back-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #8900f2;
  border-radius: 0.6em;
  color: #8900f2;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  opacity: 0;
}

.back-btn:hover, .back-btn:focus {
  color: #fff;
  outline: 0;
}

.first-back {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-back:hover {
  box-shadow: 0 0 40px 40px #8900f2 inset;
}

.contact-form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 3.72rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
  background-color: white;
}

.contact-form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: white;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  border: solid 2px #8900f2;
  transition: all 0.3s;
}

.contact-form__input:placeholder-shown + .contact-form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.btn-div {
    text-align: right;
    margin-right: 50px;
}

.submit-header {
  font-family: 'Courier New', Courier, monospace;
}

/* Dark Mode */

.c-wrapper-dark {
  background-color: #1b1a1a;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 250vh;
  max-height: 100vh;
}

.c-header-dark {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.c-load-screen-dark {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #7bdff2;
  width: 100%;
  height: 0%;
}

.contact-header-dark {
    background-color: #7bdff2;
    font-size: 40px;
    text-align: center;
    color: white;
}

.contact-btn-dark {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #6ed3e7;
  border-radius: 0.6em;
  color: #6ed3e7;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.contact-btn-dark:hover, .contact-btn-dark:focus {
  color: #fff;
  outline: 0;
}

.first-contact-dark {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-contact-dark:hover {
  box-shadow: 0 0 40px 40px #6ed3e7 inset;
}

.c-load-text-dark {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: #1b1a1a;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.contact-card-dark {
  background-color: #46494c;
}

.contact-form__input-dark {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1.2rem;
    margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: #46494c;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  border: solid 2px #6ed3e7;
  transition: all 0.3s;
}

.contact-form__label-dark {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 3.72rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
  background-color: #46494c;
  color: white;
}

.contact-form__input-dark:placeholder-shown + .contact-form__label-dark {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.contact-card-footer-dark {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-size: 15px;
  color: white;
}

.back-btn-dark {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #6ed3e7;
  border-radius: 0.6em;
  color: #6ed3e7;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  opacity: 0;
}

.back-btn-dark:hover, .back-btn-dark:focus {
  color: #fff;
  outline: 0;
}

.first-back-dark {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-back-dark:hover {
  box-shadow: 0 0 40px 40px #6ed3e7 inset;
}

.submit-header-dark {
  color: white;
  font-family: 'Courier New', Courier, monospace;
}