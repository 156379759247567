.a-wrapper {
  background-color: #D9D9D9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 250vh;
  max-height: 100vh;
}

.a-header {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.a-load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #8900f2;
  width: 100%;
  height: 0%;
}

.a-load-text {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.mt200 {
  margin-top: 200px;
}

.mb175 {
  margin-bottom: 175px;
}

.text-col {
  text-align: left;
  margin-left: 30px;
}

.text-div {
  color: #8900f2;
}

.text-header {
  margin-bottom: 15px;
  font-family: 'Courier New', Courier, monospace;
  color: black;
}

.images-selector input {
  position: absolute;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.images-selector input:checked + .img-card {
  filter: none;
  transform: scaleY(1);
}

.img-card {
  display: inline-block;
  width: 300px;
  height: 300px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #8900f2;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 200ms ease-in;
  filter: grayscale(1) opacity(.8);
}

.img-card:hover {
  filter: grayscale(0) opacity(1);
}

.img-card::before,
.img-card::after {
  transform: scaleY(-1);
}

.img1 {
  background-image: url(../../images/me-icecream_150x150.jpg);
  margin-right: 20px;
}

.img2 {
  background-image: url(../../images/kelley_and_I_150x150.jpg);
  margin-right: 20px;
}

.img3 {
  background-image: url(../../images/Profile_Picture_150x150.jpg);
}

.contact-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #8900f2;
  border-radius: 0.6em;
  color: #8900f2;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.contact-btn:hover, .contact-btn:focus {
  color: #fff;
  outline: 0;
}

.first-contact {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-contact:hover {
  box-shadow: 0 0 40px 40px #8900f2 inset;
}

/* Dark Mode */

.a-wrapper-dark {
  background-color: #1b1a1a;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 250vh;
  max-height: 100vh;
}

.a-header-dark {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.a-load-screen-dark {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #7bdff2;
  width: 100%;
  height: 0%;
}

.a-load-text-dark {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: #1b1a1a;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.text-div-dark {
  color: #6ed3e7;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.text-header-dark {
  color: white;
  margin-bottom: 15px;
  font-family: 'Courier New', Courier, monospace;
}

.img-card-dark {
  display: inline-block;
  width: 300px;
  height: 300px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #7bdff2;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 200ms ease-in;
  filter: grayscale(1) opacity(.8);
}

.img-card-dark:hover {
  filter: grayscale(0) opacity(1);
}

.img-card-dark::before,
.img-card-dark::after {
  transform: scaleY(-1);
}

.contact-btn-dark {
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #6ed3e7;
  border-radius: 0.6em;
  color: #6ed3e7;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.contact-btn-dark:hover, .contact-btn-dark:focus {
  color: #fff;
  outline: 0;
}

.first-contact-dark {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.first-contact-dark:hover {
  box-shadow: 0 0 40px 40px #6ed3e7 inset;
}