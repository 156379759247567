.p-load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #8900f2;
  width: 100%;
  height: 0%;
}

.p-load-text {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.projects {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0%);
}

.p-header {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 30px;
}

.container .p-card .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    z-index: 1;
}

.container .p-card .icon .fa {
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    color: #fff;
}

.p-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.6em;
  cursor: pointer;
  font-size: 0.90rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.75em 2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: black;
}

.p-btn:hover, .p-btn:focus {
  color: #fff;
  outline: 0;
}

.p-btn-first {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.p-btn-first:hover {
  box-shadow: 0 0 40px 40px #8d99ae inset;
  color: white;
}


i {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
}

.container .p-card .face {
    width: 300px;
    height: 200px;
    transition: 0.5s;
}

.container .p-card .face.face1 {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
}

.container .p-card:hover .face.face1{
    transform: translateY(0px);
}

.container .p-card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.container .p-card:hover .face.face1 .content {
    opacity: 1;
}

.container .p-card .face.face1 .content i{
    max-width: 100px;
}

.container .p-card .face.face2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0.2,0.2,0.2,0.2);
    border-radius: .25rem;
    transform: translateY(-100px);
}

.container .p-card:hover .face.face2{
    transform: translateY(0);
}

.container .p-card .face.face2 .content p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #414141;
}

.container .p-card .face.face2 .content h3 {
    margin: 0 0 10px 0;
    padding: 0;
    color: #fff;
    font-size: 24px;
    text-align: center;
    color: #414141;
} 

.container a {
    text-decoration: none;
    color: #414141;
}

.content {
    text-align: center;
}

.p-description {
  color: white;
  background-color: #8d99ae;
}


/* Dark Mode */

.p-load-screen-dark {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #7bdff2;
  width: 100%;
  height: 0%;
}

.p-load-text-dark {
    position: relative;
    justify-content: center;
    text-align: center;
    top: 45%;
    color: #1b1a1a;
    font-family: 'Courier New', Courier, monospace;
    opacity: 0;
}

.p-btn-dark {
      box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border-radius: 0.6em;
  cursor: pointer;
  font-size: 0.90rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.75em 2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: black;
}

.p-btn-dark:hover, .p-btn-dark:focus {
  color: #fff;
  outline: 0;
}

.p-btn-first-dark {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.p-btn-first-dark:hover {
  box-shadow: 0 0 40px 40px #bfc0c0 inset;
  color: white;
}

.p-description-dark {
  background-color: #bfc0c0;
}